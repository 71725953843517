import React from "react"
import stCss from '../../st-css';
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import {variant, styleProps, sizeShorthandProps, marginShorthandProps, visibilityShorthandProps} from 'st-css';
import { Link } from '../link/link';

export const ImageStyled = stCss.canonize(GatsbyImage)((props) => ({
    transform: 'translateZ(0)',
    boxShadow: props.shadow ? '10px 10px 50px -20px rgba(6, 55, 71, 0.62)' : 'none'
}),
  variant('type', {
    default: {},
    circle: {
        borderRadius: '50%',
        boxShadow: '10px 10px 50px -20px rgba(6, 55, 71, 0.62)'
    },
    rounded: {
        borderRadius: '24px',
        boxShadow: '10px 10px 50px -20px rgba(6, 55, 71, 0.62)'
    },
    roundedBottomLeft: {
        borderRadius: '24px'
    },
    roundedLeft: {
        borderTopLeftRadius: '24px',
        borderBottomLeftRadius: '24px',
        boxShadow: '10px 10px 50px 15px rgba(6, 55, 71, 0.62)'
    },
    roundedBottom: {
        borderBottomLeftRadius: '24px',
        borderBottomRightRadius: '24px',
        boxShadow: '10px 10px 50px -20px rgba(6, 55, 71, 0.62)'
    },
    bg: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0
    }
  }, ['default']),
  styleProps(sizeShorthandProps, marginShorthandProps, visibilityShorthandProps)
);

export const getAltFromName = (name) => {
    return name.replace(/[_-]+/g, ' ')
        .toLowerCase()
        .replace(/(^\w|\b\w)/g, function(m) { return m.toUpperCase(); }) // title case words
        .replace(/\s+/g, ' ')
        .replace(/^\s+|\s+$/, '');
}

export const Image = ({image, alt, to, name = '', ...props}) => {
    if (!image) return '';
    if (to) {
        return <Link to={to}><ImageStyled alt={alt || getAltFromName(name)} image={getImage(image)} {...props} /></Link>
    }
    return <ImageStyled alt={alt || getAltFromName(name)} image={getImage(image)} {...props} />;
}