import React, { useState, useCallback } from 'react';
import { Content } from '../content/content';
import stCss from '../../st-css';
import { styleProps, textShorthandProps, spacingShorthandProps, sizeShorthandProps, visibilityShorthandProps } from 'st-css';
import { useSwipeable, SwipeEventData } from 'react-swipeable';

export const CarouselStyled = stCss.canonize('div')({
    position: 'relative',
    mx: 'auto',
    zIndex: 1
}, styleProps(textShorthandProps, sizeShorthandProps, spacingShorthandProps, visibilityShorthandProps));

export const Carousel = ({ startIndex = 0, maxVisible = 2, children, ...props }) => {
    const [ currentIndex, setCurrentIndex ] = useState(startIndex);

    const onClick = useCallback((index) => {
        if (currentIndex === index) {
            setCurrentIndex((index + 1) % children.length);
        }
        else {
            setCurrentIndex(index);
        }
    }, [currentIndex, setCurrentIndex]);

    const swipeEvents = useSwipeable({
        onSwiped: (eventData) => setCurrentIndex(((index + (eventData.dir === 'Right' ? -1 : 1)) % children.length))
    });

    return (
        <CarouselStyled {...props}>
            {React.Children.map(children, (child, index) => (
                React.cloneElement(child, { 
                    position: index - currentIndex, 
                    visible: Math.abs(index - currentIndex) < maxVisible,
                    onClick: () => onClick(index),
                    ...(index === currentIndex ? swipeEvents : {})
                })
            ))}
        </CarouselStyled>                
    )
}

export const CarouselItem = stCss.canonize('div')(( { position, visible }) => ({
    zIndex: `${-Math.abs(position)}`,
    top: 0,
    left: 0,
    w: 1,
    position: 'absolute',
    transition: 'all .3s ease',
    transform: `translateX(${ position * 100}px) scale(${ 1 - Math.abs(position) * .2  })`,
    opacity: visible ? (position ? .7 : 1) : 0,
    cursor: 'pointer'
}));