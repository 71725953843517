import React from 'react';
import { Layout } from './src/components/layout/layout';
import stCss from './src/st-css';

// remove style tag generated on the server to avoid conflicts with the one added on the client
export const onInitialClientRender = () => {
  stCss.hydrate();
};

export const wrapPageElement = ({ element, props }) => {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  return <Layout {...props}>{element}</Layout>;
};
