import { Link } from '../link/link';
import stCss from '../../st-css';
import {variant, styleProps, sizeShorthandProps, visibilityShorthandProps, marginShorthandProps} from 'st-css';

export const Button = stCss.canonize(Link)((p) => ({
    display: 'inline-block',
    fontFamily: 'Antonio',
    fontWeight: 'bold',
    fontSize: '18px',
    lineHeight: '1em',
    textAlign: 'center',
    letterSpacing: .75,
    textDecoration: 'none',
    border: 'none',
    padding: ['.75em 1em', '.65em 1.5em'],
    opacity: p.disabled ? '.5' : 1,
    ':hover': {
        textDecoration: 'none',
        cursor: 'pointer'
    },
    ' + a': {
        ml: [16, 24, 32]
    }
}),
    variant('variant', (p) => ({
        default: {
            textTransform: 'uppercase',
            fontSize: '18px',
            fontWeight: 'normal',
            border: '2px solid #0085FF',
            color: `#0085FF`,
        },
        glass: {
            textTransform: 'uppercase',
            fontSize: '18px',
            fontWeight: 'normal',
            border: '2px solid #0085FF',
            color: `#FFFFFF`,
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
            backdropFilter: 'blur(7px)'
        },
        wide: {
            height: '62px',
            width: p.width || '345px',
            maxWidth: '100%',
            borderRadius: '40px',
            lineHeight: '42px',
            backgroundColor: '#2F80ED',
            color: `#fff !important`,
            transition: 'opacity .1s linear',
            ':hover': {
                opacity: .8
            }
        },
        underline: {
            h: 36,
            padding: [0],
            borderBottom: '2px solid #F73FC3',
            color: '#F73FC3 !important',
        }
    }), 'default')
,
    styleProps(sizeShorthandProps, marginShorthandProps, visibilityShorthandProps)
);