import React from 'react';
import stCss from '../../st-css';
import { styleProps, spacingShorthandProps, sizeShorthandProps, visibilityShorthandProps, textShorthandProps } from 'st-css';

export const IconStyled = stCss.canonize('svg')({

},
styleProps(textShorthandProps, spacingShorthandProps, visibilityShorthandProps, sizeShorthandProps)
);

const LinearGradient = ({gradient, id}) => (
    <linearGradient id={id}>
        {gradient.map((g, i) => {
            const color = Array.isArray(g) ? g[0] : g;
            const offsetOrDefault = Array.isArray(g) ? g[1] : i / (gradient.length - 1);
            return <stop key={i} offset={`${offsetOrDefault <= 1 ? offsetOrDefault * 100 : offsetOrDefault}%`} stopColor={color} />
        })};
    </linearGradient>
);

export const Icon = ({icon, ...props}) => {

    return (
        <IconStyled {...props}>
            <use xlinkHref={`/icons.080a7b4de5214fa1ea74.svg#sprite-${icon}`} />
        </IconStyled>
    )
}


export const GradientIcon = ({icon, gradient, ...props}) => {

    const gradientId = Math.random().toString(36).substring(7);

    return (
        <IconStyled viewBox={viewBox} {...props}>
            <defs>
                <LinearGradient gradient={gradient} id={gradientId} />
            </defs>
            <use xlinkHref={`#${icon}`} fill={`url(#${gradientId})`}/>
        </IconStyled>
    );
}