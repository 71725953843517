import React, { cloneElement, Children, useState, useCallback } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { Content } from '../content/content';
import { Subtitle } from '../subtitle/subtitle';
import { Icon } from '../icon/icon';
import { Grid } from '../grid/grid';

export const getFaqsQuery = graphql`
  query {
    allMdx(
      sort: { fields: [frontmatter___order], order: ASC }
      filter: { fields: { sourceInstanceName: { eq: "faq" } } }
    ) {
      nodes {
        body
        frontmatter {
          question
        }
      }
    }
  }
`;

export const useFaqs = () => {
  const { allMdx } = useStaticQuery(getFaqsQuery);
  return allMdx.nodes.map((n) => ({ ...n.frontmatter, answer: n.body }));
};

export const Expander = ({ index, title, children, open, onChange }) => {
  const [expanded, setExpanded] = useState(false);
  const isOpen = open === undefined ? expanded : open;

  const onClick = useCallback(() => {
    if (onChange) {
      onChange(!isOpen, index);
    } else {
      setExpanded(!expanded);
    }
  }, [isOpen, expanded, index, onChange]);

  return (
    <Content
      css={{ ':hover': { cursor: 'pointer' }, pb: 24, borderBottom: '2px solid #E5E5E5', ' p:last-child': { pb: 0 } }}
      onClick={onClick}
    >
      <Grid layout="columns" columnTemplate="32px 1fr">
        <Icon icon={isOpen ? 'minus' : 'plus'} w={32} height={32} mt={28} css={{ stroke: '#0085FF' }} />
        <Subtitle as="h3" mb={0} mt={24} size={['20px', '25px', '28px']}>
          {title}
        </Subtitle>
      </Grid>
      {isOpen && (
        <Content ml={[16, 64, 80]} mt={32} pl={16} css={{ borderLeft: '2px solid #0085FF' }}>
          {children}
        </Content>
      )}
    </Content>
  );
};

export const Accordion = ({ children }) => {
  const [openedIndex, setOpenedIndex] = useState();

  const onChange = useCallback(
    (open, index) => {
      if (open) {
        setOpenedIndex(index);
      } else if (index === openedIndex) {
        setOpenedIndex(undefined);
      }
    },
    [openedIndex],
  );

  return (
    <Content mb={48} ml={[0, 0, 32, 64]}>
      {Children.map(children, (child, index) => cloneElement(child, { index, onChange, open: openedIndex === index }))}
    </Content>
  );
};

export const Faqs = () => {
  const faqs = useFaqs();
  return (
    <>
      <Subtitle
        css={{
          textTransform: 'none',
          borderBottom: '2px solid #0085FE',
          display: 'inline-block',
          pb: 24,
          pr: 64,
          mb: 64,
        }}
      >
        FAQs
      </Subtitle>
      <Accordion>
        {faqs.map(({ question, answer }, i) => (
          <Expander key={i} title={question}>
            <MDXRenderer>{answer}</MDXRenderer>
          </Expander>
        ))}
      </Accordion>
    </>
  );
};
