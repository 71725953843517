import React from "react"
import stCss from '../../st-css';
import {variant, styleProps, textShorthandProps, spacingShorthandProps, visibilityShorthandProps} from 'st-css';
import { Link as GatsbyLink } from "gatsby"

const linkStyles = [
    variant('variant', {
        default: {
            textDecoration: 'underline',
            color: '#0085FF',
            ':hover': {
                opacity: .9
            }
        },
        unstyled: {
            textDecoration: 'none',
            color: 'inherit'
        }
    }, 'default'),
    styleProps(textShorthandProps, spacingShorthandProps, visibilityShorthandProps)
]

export const InternalLink = stCss.canonize(GatsbyLink)(...linkStyles);
export const ExternalLink = stCss.canonize('a')(...linkStyles);
export const AnchorLink = stCss.canonize('a')(...linkStyles);

export const Link = ({to='', forceAbsolutePath, ...props}) => {
    if (to.startsWith('http')){
        return <ExternalLink href={to} rel="noreferrer" target="_blank" {...props} />;
    }

    if (to.startsWith('#') || to.startsWith('tel:') || to.startsWith('mailto:')){
        return <AnchorLink href={to} {...props} />;
    }

    let resolvedTo = forceAbsolutePath && !to.startsWith('/') ? '/' + to : to;
    if (!resolvedTo.endsWith('/')){
        resolvedTo = resolvedTo + '/'
    }

    return <InternalLink to={resolvedTo} {...props} />;
}
