import React from "react"
import stCss from '../../st-css';
import {styleProps, sizeShorthandProps, visibilityShorthandProps, marginShorthandProps, textShorthandProps} from 'st-css';

// todo: pull dynamically
const chesterfieldHours = [
    {
        day: 'Monday',
        hours: '9:00am - 7:00pm'
    },
    {
        day: 'Tuesday',
        hours: '9:00am - 5:00pm'
    },
    {
        day: 'Wednesday',
        hours: '9:00am - 7:00pm'
    },
    {
        day: 'Thursday',
        hours: '9:00am - 5:00pm'
    },
    {
        day: 'Friday',
        hours: '9:00am - 5:00pm'
    },
    {
        day: 'Saturday',
        hours: false
    },
    {
        day: 'Sunday',
        hours: false
    }
];

const pontiacHours = [
    {
        day: 'Monday',
        hours: '9:00am - 5:00pm'
    },
    {
        day: 'Tuesday',
        hours: '9:00am - 7:00pm'
    },
    {
        day: 'Wednesday',
        hours: '9:00am - 5:00pm'
    },
    {
        day: 'Thursday',
        hours: '9:00am - 5:00pm'
    },
    {
        day: 'Friday',
        hours: '9:00am - 5:00pm'
    },
    {
        day: 'Saturday',
        hours: false
    },
    {
        day: 'Sunday',
        hours: false
    }
]

export const HoursStyled = stCss.canonize('div')({
    color: '#000103',
    display: 'grid',
    maxWidth: '400px',
    gridTemplateColumns: 'max-content max-content',
    rowGap: '24px',
    columnGap: '48px'
}, styleProps(sizeShorthandProps, marginShorthandProps, visibilityShorthandProps, textShorthandProps));

export const Hours = ({ location, ...gridProps}) => (
    <HoursStyled {...gridProps}>
        {(location === 'chesterfield' ? chesterfieldHours : pontiacHours).map(({day, hours}) => {
            const props = hours ? {} : { className: 'closed' };
            return (
                <>
                    <div {...props}>{day}</div>
                    <div {...props}>{hours || 'Closed'}</div>
                </>
            );
        })}
    </HoursStyled>
);