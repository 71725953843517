import stCss from '../../st-css';
import { styleProps, textShorthandProps, spacingShorthandProps, visibilityShorthandProps } from 'st-css';

export const Avatar = stCss.canonize('div')({
    backgroundColor: 'rgba(0, 133, 255)',
    fontSize: '24px',
    fontWeight: 'bold',
    textAlign: 'center',
    borderRadius: '50%',
    width: '70px',
    height: '70px',
    padding: '20px'
},
styleProps(textShorthandProps, spacingShorthandProps, visibilityShorthandProps));