import stCss from '../../st-css';
import { styleProps, variant, spacingShorthandProps, sizeShorthandProps, textShorthandProps, visibilityShorthandProps } from 'st-css';

export const Subtitle = stCss.canonize('h2')({
    color: '#0085FF',
    fontWeight: 'bold',
    lineHeight: '1.5em',
    marginBottom: '24px',
}, variant('as', {
    h2: {
        fontSize: ['30px', '32px', '34px', '36px'],
        textTransform: 'uppercase',
    },
    h3: {
        color: '#000',
        fontSize: ['23px', '25px', '28px'],
    },
    h4: {
        textTransform: 'uppercase',
        fontWeight: 'normal',
        fontSize: ['17px', '19px', '21px']
    },
    h5: {
        textTransform: 'uppercase',
        fontSize: ['14px', '14px', '16px']
    }
}, 'h2'),
styleProps(textShorthandProps, spacingShorthandProps, sizeShorthandProps, visibilityShorthandProps));