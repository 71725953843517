import React, { useState } from 'react';
import { Grid } from '../grid/grid';
import { Button } from '../button/button';
import { Text } from '../text/text';
import { Input, Select, TextArea } from '../input/input';
import { Content } from '../content/content';

export const ContactForm = () => {
  const [formState, setFormState] = useState('init');

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = [...formData.keys()].reduce((obj, key) => {
      const value = formData.getAll(key).join(', ').trim();
      if (value) {
        obj[key] = value;
      }
      return obj;
    }, {});
    if (data.firstName) return;

    setFormState('submitting');

    await fetch(`https://api.${window.location.host}/contact`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    e.target.reset();
    setFormState('submitted');
  };

  return (
    <Content mt={32} pr={48}>
      <form onSubmit={handleSubmit}>
        <Input name="firstName" className="honey" />
        <Grid layout="rows" columnTemplate={['1fr', '1fr', '400px', '1fr 1fr']} rowGap="24px" mb={64}>
          <Content css={{ gridColumn: ['1', '1', '1', '1 / span 2'] }}>
            <Select name="location" label="Select a Location" required options={['Chesterfield', 'Pontiac']} />
          </Content>
          <Input name="name" label="Full Name" required />
          <Input name="email" label="Email" type="email" required />
          <Input name="phone" label="Phone" type="phone" />
          <Input name="date" label="Date" type="date" />
        </Grid>
        <TextArea name="notes" label="Notes for the Doctor" rows="4" />
        {formState === 'submitted' ? (
          <Text size={16} mt={48} color="#fff">
            Thanks for contacting us. Someone from our office will be in touch shortly.
          </Text>
        ) : (
          <Button
            as="button"
            css={{ backgroundColor: 'rgba(255, 255, 255, 0.2)' }}
            mt={64}
            variant="default"
            type="submit"
            disabled={formState === 'submitting' ? true : undefined}
          >
            {formState === 'submitting' ? 'Sending request...' : 'Book an Appointment'}
          </Button>
        )}
      </form>
    </Content>
  );
};
