import stCss, {
  styleProps,
  variant,
  spacingShorthandProps,
  visibilityShorthandProps,
  sizeShorthandProps,
} from '../../st-css';

export const Container = stCss.canonize('div')(
  variant(
    'type',
    {
      default: {
        position: 'relative',
        px: [20, 32],
        w: '100vw',
        maxw: 1440,
        mx: 'auto',
      },
      collapsed: {},
    },
    'default',
  ),
  styleProps(spacingShorthandProps, visibilityShorthandProps, sizeShorthandProps),
);
