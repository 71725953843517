import React from "react"
import stCss from '../../st-css';
import {variant, styleProps, sizeShorthandProps, textShorthandProps, paddingShorthandProps} from 'st-css';
import { spacingShorthandProps } from "st-css";

export const SectionStyled = stCss.canonize('section')({
  position: 'relative',
  width: '100%',
  backgroundColor: '#fff'
},
    variant('layout', {
      default: {
        py: [32, 48],
      },
      hero: {
        pb: [0],
        height: '100vh'
      },
      fullscreen: {
        p: [0],
        width: '100vw',
        height: '100vh'
      }
    }, 'default'),
    styleProps(sizeShorthandProps, textShorthandProps, paddingShorthandProps, spacingShorthandProps),
);

export const Section = ({children, containerProps={}, ...props}) => (
    <SectionStyled {...props}>
      {children}
    </SectionStyled>
);