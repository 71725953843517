import React from "react"
import stCss from '../../st-css';

export const HeaderStyled = stCss.canonize('header')({
  position: 'absolute',
  left: 0,
  right: 0,
  zIndex: 1,
  h: 80,
  transition: '.2s ease-in-out height',
  ' > div': {
      h: 1,
      px: [0, 32],
      mx: 'auto',
      display: 'flex',
      alignItems: 'center',
      maxw: 1500,
      justifyContent: 'space-between'
  }
});

export const Header = ({children, ...props}) => {
    return (
        <HeaderStyled {...props}>
            <div>
                {children}
            </div>
        </HeaderStyled>
    )
}