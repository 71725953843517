import stCss from '../../st-css';
import {variant, styleProps, sizeShorthandProps, spacingShorthandProps, textShorthandProps, visibilityShorthandProps } from 'st-css';

const centerOrphans = (i) => i > 1 ? ({
    ' > *': {
        gridColumn: 'span 2',
        [`:last-child:nth-child(${i}n - ${i-1}):not(:first-child)`]: {
            gridColumnEnd: Math.ceil((i*3 + 1) / 2)
        }
    }
}) : {};

const tileLayouts = (() => [0,1,2,3,4,5].reduce((acc, i) => ({ ...acc, [`tiles-${i}`]: {
    gridTemplateColumns: `repeat(${i * (i > 1 ? 2 : 1) | 'auto-fill'}, 1fr)`,
    ...centerOrphans(i)
}}), {}));

export const Grid = stCss.canonize('div')(({ alignItems, gap, columnGap, rowGap }) => ({
    display: 'grid',
    alignItems: alignItems || 'normal',
    rowGap: rowGap || gap || ['24px', '32px', '48px', '64px'],
    columnGap: columnGap || gap || ['24px', '32px', '48px', '64px'],
    w: 1
}), 
variant('layout', ({ template, columnTemplate, rowTemplate, equalHeight, equalWidth }) => ({ 
    ...tileLayouts(),
    'columns': {
        gridAutoFlow: 'column',
        gridAutoColumns: equalWidth ? '1fr' : 'auto',
        gridTemplateColumns: columnTemplate || template || 'none',
        gridTemplateRows: rowTemplate || 'none'
    },
    'rows': {
        gridAutoFlow: 'row',
        gridAutoRows: equalHeight ? '1fr' : 'auto',
        gridTemplateRows: rowTemplate || template || 'none',
        gridTemplateColumns: columnTemplate || 'none'
    },
    'wall': {
        maxWidth: '100vw',
        gridAutoFlow: 'column',
        gridAutoColumns: 'max-content',
        overflowX: 'scroll',
        scrollbarWidth: 'none',
        '::-webkit-scrollbar': {
            display: 'none'
        }
    }
})),
styleProps(sizeShorthandProps, spacingShorthandProps, textShorthandProps, visibilityShorthandProps));