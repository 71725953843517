import React, { useState } from 'react';
import { Button } from '../button/button';
import { Subtitle } from '../subtitle/subtitle';
import { Content } from '../content/content';
import { Input } from '../input/input';

export const NewsletterForm = (props) => {
  const [formState, setFormState] = useState('init');

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = [...formData.keys()].reduce((obj, key) => {
      const value = formData.getAll(key).join(', ').trim();
      if (value) {
        obj[key] = value;
      }
      return obj;
    }, {});
    if (data.firstName) return;

    setFormState('submitting');

    await fetch(`https://api.${window.location.host}/contact`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    if (data.firstName) return;

    e.target.reset();
    setFormState('submitted');
  };

  return (
    <form onSubmit={handleSubmit} style={{ marginBottom: '40px' }} {...props}>
      {formState === 'submitted' ? (
        <Subtitle as="h4" align="center" hide={[1, 0]} mb={24}>
          Thanks for signing up.
        </Subtitle>
      ) : (
        <>
          <Input hide name="firstName" />
          <Content css={{ position: 'relative' }}>
            <Input type="email" placeholder="Enter your email" name="newsletterSignup" required />
            <input type="submit" style={{ width: 0, height: 0, padding: 0 }} value="" />
          </Content>
        </>
      )}
    </form>
  );
};
