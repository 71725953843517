import React from "react"
import stCss from '../../st-css';
import {variant, styleProps, visibilityShorthandProps} from 'st-css';

const sharedProps = {
    backgroundColor: '#fff',
    position: 'absolute',
    width: '46px',
    height: '2px'
}

const beforeAfterProps = {
    ...sharedProps,
    display: 'block',
    content: '""',
    transition: 'transform 0s cubic-bezier(.645,.045,.355,1) .1s'
}

export const MenuToggleStyled = stCss.canonize('div')(p => ({
    position: 'absolute',
    top: '30px',
    right: '20px',
    overflow: 'visible',
    zIndex: 99999,
    cursor: 'pointer',
    ' > div': {
        position: 'relative',
        display: 'inline-block',
        width: '46px',
        height: '24px',
        ' > div': {
            top: '50%',
            display: 'block',
            marginTop: '-2px',
            transition: 'transform .25s ease',
            ...sharedProps
        },
        ' > div:before': {
            top: '-12px',
            ...beforeAfterProps
        },
        ' > div:after': {
            bottom: '-12px',
            ...beforeAfterProps
        }
    }
}), variant('animation', p => p.open ? ({
    '3dx': {
        ' > div > div': {
            transform: 'rotateY(180deg)',
            backgroundColor: 'transparent'
        },
        ' > div > div:before': {
            transform: 'translate3d(0,12px,0) rotate(45deg)',
        },
        ' > div > div:after': {
            transform: 'translate3d(0,-12px,0) rotate(-45deg)'
        }
    }    
}) : {}, '3dx'),
styleProps(visibilityShorthandProps)
);

export const MenuToggle = (props) => {
    return (
        <MenuToggleStyled {...props}>
            <div>
                <div>

                </div>
            </div>
        </MenuToggleStyled>
    )
};