import { createElement } from 'react';
import { StCss, spacingShorthands, sizeShorthands, textShorthands, visibilityShorthands } from 'st-css';
export * from 'st-css';

const stCss = new StCss({
  pragma: createElement,
  breakpoints: [
    ['none', '719px'],
    ['720px', '1023px'],
    ['1024px', '1499px'],
    ['1500px', 'none'],
  ],
  transformers: [spacingShorthands, sizeShorthands, textShorthands, visibilityShorthands],
});

export default stCss;
