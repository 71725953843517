import stCss from '../../st-css';
import {variant, styleProps, visibilityShorthandProps, textShorthandProps, sizeShorthandProps, spacingShorthandProps } from 'st-css';

export const Card = stCss.canonize('div')(
  variant('layout', {
    passthrough: {},
    default: {
      backgroundColor: '#EDF4FF',
      borderRadius: '20px',
      padding: '24px',
      boxShadow: '-10px -10px 50px #FFFFFF, 10px 10px 50px -20px rgba(6, 55, 71, 0.62)',
      maxw: 400,
      mx: 'auto'
    }
  }, 'default')
,
styleProps(visibilityShorthandProps, textShorthandProps, sizeShorthandProps, spacingShorthandProps)
);