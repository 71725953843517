import React from "react"
import stCss from '../../st-css';
import {Container} from '../container/container'

// todo: pull dynamically
const copyright = "All Rights Reserved"

export const CopyrightStyled = stCss.canonize('footer')({
    h: 68,
    fontSize: '12px',
    lineHeight: '20px',
    background: '#000103',
    color: '#fff',
    textAlign: 'center',
    display: 'grid',
    gridAutoFlow: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    columnGap: '8px'
});

export const Copyright = ({children, containerProps, ...props}) => {
    return (
        <CopyrightStyled {...props}>
            <Container css={{ borderTop: '2px solid #0085FF'}} py={16}>
                <span>&copy;{new Date().getFullYear()}&nbsp;{copyright}</span>
                {children}
            </Container>
        </CopyrightStyled>
    )
}