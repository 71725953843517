import React from "react"
import stCss from '../../st-css';

export const FooterStyled = stCss.canonize('footer')({
    background: '#000103',
    pt: 90,
    pb: 180
});

export const Footer = ({children, ...props}) => {
    return (
        <FooterStyled {...props}>
            {children}
        </FooterStyled>
    )
}