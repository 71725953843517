import { createContext, useState, useRef, useEffect } from 'react';
import { Footer } from '../footer/footer';
import { Copyright } from '../copyright/copyright';
import './layout.css';
import { Container } from '../container/container';
import { Header } from '../header/header';
import { Nav } from '../nav/nav';
import { Link } from '../link/link';
import { Section } from '../section/section';
import { Grid } from '../grid/grid';
import { ConstrainedImage } from '../image/constrained-image';
import { Content } from '../content/content';
import { Subtitle } from '../subtitle/subtitle';
import { ContactForm } from '../contact-form/contact-form';
import { Hours } from '../hours/hours';
import { Text } from '../text/text';
import { Testimonials } from '../testimonial/testimonial';
import { MenuToggle } from '../menu-toggle/menu-toggle';
import { Icon } from '../icon/icon';
import { Carousel, CarouselItem } from '../carousel/carousel';
import { Card } from '../card/card';
import { Divider } from '../divider/divider';
import { NewsletterForm } from '../newsletter-form/newsletter-form';

export const LayoutContext = createContext({
  navOpen: false,
});

export const Layout = ({ children, location }) => {
  const [navOpen, setNavOpen] = useState(false);

  const myRef = useRef({
    location,
  });

  useEffect(() => {
    // set the location on initial load
    if (!myRef.current.location) myRef.current.location = location;
    // then make sure dialog is closed on route change
    else if (myRef.current.location !== location) {
      if (navOpen) setNavOpen(false);
      myRef.current.location = location;
    }
  }, [setNavOpen, navOpen, location]);

  return (
    <LayoutContext.Provider value={{ navOpen }}>
      <Header css={{ height: navOpen ? ['calc(100vh - 120px)', 'calc(100vh - 120px)', '80px'] : '80px' }}>
        <Link
          to="/"
          css={{
            zIndex: 1,
            position: 'absolute',
            top: ['8px', '8px', '8px', '16px'],
            left: ['16px', '32px', '32px', '2%'],
          }}
        >
          <Icon icon="dr-todd-logo" css={{ w: 75, h: 60 }} />
        </Link>
        <Container maxw={1500} h={1} css={{ borderBottom: '2px solid white' }}>
          <Nav hide={[1, 1, 0]} h={1}>
            <Link to="/about">About</Link>
            <Link to="/services/neuropathy">Neuropathy</Link>
            <Link to="/services/chiropractic-care">Chiropractic Care</Link>
            <Link to="/services/weight-loss">Weight Loss</Link>
            <Link to="/services/pemf">PEMF</Link>
          </Nav>
          <MenuToggle show={[1, 1, 0]} open={navOpen} onClick={() => setNavOpen(!navOpen)} />
        </Container>
      </Header>
      {children}
      <Content
        show={[1, 1, 0]}
        css={{
          position: 'absolute',
          top: '80px',
          left: 0,
          right: 0,
          bottom: '120px',
          opacity: navOpen ? 1 : 0,
          transition: 'opacity .1s linear',
          transitionDelay: navOpen ? '.2s' : '0s',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end',
        }}
      >
        <Nav h="400px" layout="stack" css={{ zIndex: 1 }}>
          <Link to="/about">About</Link>
          <Link to="/services/neuropathy">Neuropathy</Link>
          <Link to="/services/chiropractic-care">Chiropractic Care</Link>
          <Link to="/services/weight-loss">Weight Loss</Link>
          <Link to="/services/pemf">PEMF</Link>
        </Nav>
        <Content color="#fff" css={{ pr: 42, textAlign: 'right', mt: 48, zIndex: 1 }}>
          <Text size="16px" weight="bold">
            Chesterfield Location |{' '}
            <Link
              color="#fff !important"
              to="https://www.google.com/maps/place/Chesterfield+Family+Chiropractic+Center/@42.6834603,-82.8273306,17z/data=!4m13!1m7!3m6!1s0x88251ed3fc238b5d:0x10a6e47136ed588c!2s28039+Carriage+Way+Dr,+New+Baltimore,+MI+48051!3b1!8m2!3d42.6834564!4d-82.8251419!3m4!1s0x88251f8cde259aa5:0xbcb58aa34e618757!8m2!3d42.6834767!4d-82.8261288"
            >
              Get Directions
            </Link>
          </Text>
          <Text size="16px" weight="bold">
            Pontiac Location |{' '}
            <Link
              color="#fff !important"
              to="https://www.google.com/maps/place/Dr.+Todd's+Wellness/@42.6311226,-83.3271957,17z/data=!4m13!1m7!3m6!1s0x8824be62ae3b1645:0xf9dead869a3d58fc!2s133+S+Telegraph+Rd,+Pontiac,+MI+48341!3b1!8m2!3d42.6311187!4d-83.325007!3m4!1s0x8824bf0242fcd2e5:0x6b5d0cd35c28ef3f!8m2!3d42.6311191!4d-83.3249962"
            >
              Get Directions
            </Link>
          </Text>
        </Content>
      </Content>
      <Section
        css={{ backgroundColor: '#043B94', backgroundImage: 'linear-gradient(to bottom right, #043B94, #091E3A)' }}
      >
        <Container maxw={1000} css={{ overflow: 'hidden' }}>
          <Subtitle color="#fff" mb={['64px', '48px', '24px']}>
            Satisfied Patients All Over
          </Subtitle>
          <Testimonials />
        </Container>
        <Container mt={64} css={{ display: 'flex', justifyContent: ['left', 'center'] }}>
          <Grid
            layout={['rows', 'columns']}
            template={['200px min-content', '340px 550px']}
            alignItems="center"
            w="auto"
          >
            <ConstrainedImage
              name="dr-todd-kleinstein-headshot"
              type="circle"
              w={['200px', '340px']}
              h={['200px', '340px']}
            />
            <Content>
              <Subtitle color="#fff">Meet the Doctor</Subtitle>
              <Subtitle as="h4" color="#fff">
                Dr Todd Klenstein
              </Subtitle>
              <Text color="#fff">
                Dr. Todd Kleinstein D.C. founded Chesterfield Family Chiropractic Center P.C. in April of 2000 after
                leaving one of Michigan&apos;s largest chiropractic practices, Gregg Family Chiropractic Center.
                &quot;Dr. Todd&quot; has grown Chesterfield Family Chiropractic Center into a preeminent chiropractic
                practice with two busy locations while maintaining a committed and caring one on one relationship with
                his patients.
              </Text>
            </Content>
          </Grid>
        </Container>
      </Section>
      <Section pt={[0, 0, 48]} css={{ backgroundColor: '#000' }}>
        <Container px={['0px']} maxw={1600}>
          <Grid layout={['rows', 'rows', 'columns']} equalWidth equalHeight>
            <Content>
              <ConstrainedImage name="chesterfied-chiropractic-center-locations" />
              <Content
                p={[16, 32, 32, 64]}
                pb={[32, 64, '60px !important', 64]}
                css={{ backgroundColor: '#fff', overflow: 'hidden' }}
              >
                <Subtitle>Hours</Subtitle>
                <Grid layout="columns" hide={[1, 0]}>
                  <Content>
                    <Text size="16px" weight="bold">
                      Pontiac |{' '}
                      <Link to="https://www.google.com/maps/place/Dr.+Todd's+Wellness/@42.6311226,-83.3271957,17z/data=!4m13!1m7!3m6!1s0x8824be62ae3b1645:0xf9dead869a3d58fc!2s133+S+Telegraph+Rd,+Pontiac,+MI+48341!3b1!8m2!3d42.6311187!4d-83.325007!3m4!1s0x8824bf0242fcd2e5:0x6b5d0cd35c28ef3f!8m2!3d42.6311191!4d-83.3249962">
                        Get Directions
                      </Link>
                    </Text>
                    <Hours location="pontiac" />
                  </Content>
                  <Content>
                    <Text size="16px" weight="bold">
                      Chesterfield |{' '}
                      <Link to="https://www.google.com/maps/place/Chesterfield+Family+Chiropractic+Center/@42.6834603,-82.8273306,17z/data=!4m13!1m7!3m6!1s0x88251ed3fc238b5d:0x10a6e47136ed588c!2s28039+Carriage+Way+Dr,+New+Baltimore,+MI+48051!3b1!8m2!3d42.6834564!4d-82.8251419!3m4!1s0x88251f8cde259aa5:0xbcb58aa34e618757!8m2!3d42.6834767!4d-82.8261288">
                        Get Directions
                      </Link>
                    </Text>
                    <Hours location="chesterfield" />
                  </Content>
                </Grid>
                <Carousel show={[1, 0]} w={300} h={400} mx={'auto'}>
                  <CarouselItem>
                    <Card size="13px" w={300} h={400}>
                      <Text size="16px" weight="bold">
                        Pontiac |{' '}
                        <Link to="https://www.google.com/maps/place/Dr.+Todd's+Wellness/@42.6311226,-83.3271957,17z/data=!4m13!1m7!3m6!1s0x8824be62ae3b1645:0xf9dead869a3d58fc!2s133+S+Telegraph+Rd,+Pontiac,+MI+48341!3b1!8m2!3d42.6311187!4d-83.325007!3m4!1s0x8824bf0242fcd2e5:0x6b5d0cd35c28ef3f!8m2!3d42.6311191!4d-83.3249962">
                          Get Directions
                        </Link>
                      </Text>
                      <Hours location="pontiac" />
                    </Card>
                  </CarouselItem>
                  <CarouselItem>
                    <Card size="13px" w={300} h={400}>
                      <Text size="16px" weight="bold">
                        Chesterfield |{' '}
                        <Link to="https://www.google.com/maps/place/Chesterfield+Family+Chiropractic+Center/@42.6834603,-82.8273306,17z/data=!4m13!1m7!3m6!1s0x88251ed3fc238b5d:0x10a6e47136ed588c!2s28039+Carriage+Way+Dr,+New+Baltimore,+MI+48051!3b1!8m2!3d42.6834564!4d-82.8251419!3m4!1s0x88251f8cde259aa5:0xbcb58aa34e618757!8m2!3d42.6834767!4d-82.8261288">
                          Get Directions
                        </Link>
                      </Text>
                      <Hours location="chesterfield" />
                    </Card>
                  </CarouselItem>
                </Carousel>
              </Content>
            </Content>
            <Content px={16}>
              <Subtitle id="book-appointment">Book an Appointment</Subtitle>
              <ContactForm />
            </Content>
          </Grid>
        </Container>
      </Section>
      <Footer>
        <Container>
          <Content css={{ display: 'flex', alignItems: 'center', mx: 'auto', justifyContent: 'center' }}>
            <ConstrainedImage name="chesterfield-family-chiropractic-center-logo" w={220} />
            <Divider />
            <Icon icon="dr-todd-logo" w={64} />
          </Content>
          <Content
            css={{
              minh: ['600px', 'none'],
              p: 32,
              display: 'flex',
              justifyContent: 'space-around',
              flexDirection: ['column', 'row'],
            }}
          >
            <Content>
              <Subtitle as="h4">Navigate</Subtitle>
              <Grid layout="rows" gap="32px" css={{ color: '#fff' }}>
                <Link to="/about" variant="unstyled">
                  About Us
                </Link>
                <Link to="/privacy-policy" variant="unstyled">
                  Privacy Policy
                </Link>
                <Link to="#book-appointment" variant="unstyled">
                  Contact Us
                </Link>
              </Grid>
            </Content>
            <Content>
              <Content>
                <Subtitle as="h4">Connect with Us</Subtitle>
                <Subtitle as="h5" color="#fff">
                  Drop your email for promos and other wellness updates
                </Subtitle>
                <NewsletterForm />
                <Grid layout="columns" gap="64px" template="min-content min-content">
                  <Link to="https://www.facebook.com/DrToddsWellness">
                    <Icon icon="facebook-logo" w="24px" h="24px" />
                  </Link>
                  <Link to="https://www.instagram.com/drtoddswellness">
                    <Icon icon="instagram-logo" w="24px" h="24px" />
                  </Link>
                </Grid>
              </Content>
            </Content>
          </Content>
        </Container>
      </Footer>
      <Copyright>
        <span>&nbsp;|&nbsp;</span>
        Dr Todd Wellness & Chesterfield Family Chiropractic Center
      </Copyright>
    </LayoutContext.Provider>
  );
};
