import React from 'react';
import stCss, {
  variant,
  styleProps,
  textShorthandProps,
  sizeShorthandProps,
  visibilityShorthandProps,
} from '../../st-css';
import { Link } from '../link/link';

export const NavStyled = stCss.canonize('nav')(
  {
    w: 1,
    px: 64,
    display: 'flex',
    py: 16,
    justifyContent: 'space-around',
    alignItems: 'center',
    ' a': {
      color: '#fff',
      textDecoration: 'none',
      letterSpacing: '.75px',
      textTransform: 'uppercase',
    },
  },
  variant(
    'layout',
    {
      default: {
        fontSize: '18px',
      },
      stack: {
        height: '400px',
        flexDirection: 'column',
        fontSize: ['22px', '28px'],
        alignItems: ['flex-end', 'center'],
        px: 42
      },
    },
    'default',
  ),
  styleProps(textShorthandProps, sizeShorthandProps, visibilityShorthandProps),
);

const renderLink = (link, props) => {
  if (typeof link === 'string') {
    return (
      <Link forceAbsolutePath to={link.toLowerCase()} {...props}>
        {link.replace(/#|-|\//g, ' ').trim()}
      </Link>
    );
  } else if (Array.isArray(link)) {
    return (
      <Link forceAbsolutePath to={link[0]} {...props} {...link[2]}>
        {link[1]}
      </Link>
    );
  }
  return link;
};

export const Nav = ({ children, links = {}, linkProps = {}, ...props }) => {
  return (
    <NavStyled {...props}>
      {children}
    </NavStyled>
  );
};
