import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Image } from './image';

export const getConstrainedImagesQuery = graphql`
  query {
    allFile(filter: { sourceInstanceName: { eq: "constrained-images" } }) {
      edges {
        node {
          name
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
          }
        }
      }
    }
  }
`;

export const ConstrainedImage = (props) => {
  const { allFile } = useStaticQuery(getConstrainedImagesQuery);
  const { node } = allFile.edges.find(({ node }) => node.name === props.name) || {};
  if (!node) return '';
  return <Image image={node.childImageSharp.gatsbyImageData} {...props} />;
};
