import { graphql, useStaticQuery } from 'gatsby';
import { Carousel, CarouselItem } from '../carousel/carousel';
import { Content } from '../content/content';
import { Avatar } from '../avatar/avatar';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import stCss from '../../st-css';

export const getTestimonialsQuery = graphql`
  query {
    allMdx(
      sort: { fields: [frontmatter___order], order: ASC }
      filter: { fields: { sourceInstanceName: { eq: "testimonial" } } }
    ) {
      nodes {
        body
        frontmatter {
          name
        }
      }
    }
  }
`;

export const useTestimonials = () => {
  const { allMdx } = useStaticQuery(getTestimonialsQuery);
  return allMdx.nodes.map((n) => ({ ...n.frontmatter, quote: n.body }));
};

export const Testimonial = stCss.canonize('div')(() => ({
    w: ['calc(100vw - 80px)','630px'],
    h: [370, 280],
    fontSize: ['13px', '16px'],
    color: '#fff',
    position: 'relative',
    borderRadius: '16px',
    background: '#548cd9',
    boxShadow: '0 5px 20px 0 rgba(0,0,0,0.05), 0 2px 4px 0 rgba(0,0,0,0.10)',
    px: ['24px', '80px'],
    py: '60px',
    ' p': {
      fontSize: ['13px', '16px']  
    }
}));

export const Testimonials = () => {
  const testimonials = useTestimonials();
  return (
    <Carousel minw='264px' w={['calc(100vw - 80px)','630px']} h={[350, 280]} startIndex={1} mb={48}>
      {testimonials.map(({ quote, name }, i) => (
        <CarouselItem key={i}>
          <Testimonial>
            <Avatar css={{ position: 'absolute', top: '-35px', left: '50%', marginLeft: '-35px'}}>{name.charAt(0)}</Avatar>
            <MDXRenderer>{quote}</MDXRenderer>
            <Content css={{ textAlign: 'center', fontWeight: 'bold'}}>{name}</Content>
          </Testimonial>
        </CarouselItem>
      ))}
    </Carousel>
  );
};
