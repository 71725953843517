import React from 'react';
import stCss from '../../st-css';
import {styleProps, textShorthandProps, spacingShorthandProps, visibilityShorthandProps} from 'st-css';
import { Grid } from '../grid/grid';

export const Label = stCss.canonize('label')({
  textAlign: 'left',
  textTransform: 'uppercase',
  color: '#fff',
  fontSize: '21px'
},
  styleProps(textShorthandProps, spacingShorthandProps, visibilityShorthandProps),
);

const styles = {
  '-webkit-appearance': 'none',
  width: '100%',
  letterSpacing: '1px',
  fontSize: '21px',
  padding: '8px 8px 8px 12px',
  backgroundColor: 'rgba(255, 255, 255, 0.3)',
  ':focus': {
    backgroundColor: 'rgba(255, 255, 255, 0.6)',
  }
};

export const InputStyled = stCss.canonize('input')(
  styles,
  styleProps(textShorthandProps, spacingShorthandProps, visibilityShorthandProps),
);

export const Field = ({ name, label, labelProps = {}, children }) => (
  <Grid layout="rows" gap="16px">
    {label && <Label htmlFor={name} {...labelProps}>{label}</Label>}
    {children}
  </Grid>
);


export const Input = ({ name, label, labelProps = {}, type = 'text', ...props }) => (
  <Field name={name} label={label} labelProps={labelProps}>
    <InputStyled type={type} id={name} name={name} {...props} />
  </Field>
);

export const SelectStyled = stCss.canonize('select')(
  styles,
  styleProps(textShorthandProps, spacingShorthandProps, visibilityShorthandProps),
);

export const Select = ({ name, label, labelProps = {}, placeholder, children, options = [], ...props }) => (
  <Field name={name} label={label} labelProps={labelProps}>
      <SelectStyled as="select" id={name} name={name} {...props}>
        {placeholder && (
          <option value="" disabled selected>
            {placeholder}
          </option>
        )}
        {options.map(o => <option key={o} value={o}>{o}</option>)}
        {children}
      </SelectStyled>
    </Field>
);

export const TextAreaStyled = stCss.canonize('textarea')(
  {...styles, fontSize: '18px'},
  styleProps(textShorthandProps, spacingShorthandProps, visibilityShorthandProps),
);

export const TextArea = ({ name, label, labelProps = {}, ...props }) => (
  <Field name={name} label={label} labelProps={labelProps}>
    <TextAreaStyled name={name} id={name} {...props} />
  </Field>
);